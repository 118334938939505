import React from 'react';
import classnames from 'classnames';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import { PrimaryButton } from 'components/uiLibrary/LinkButton';
import { TP } from 'constants/index';
import { COMPONENTS, SECTIONS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import { useTranslation } from 'src/i18n';
import classes from './PricingTable.module.scss';

const RenderPlanHeader = ({ plan, onUpgradeHandler }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  return (
    <div className={classes.tableHeader__plan_container}>
      <Typography
        weight={plan.label ? 'medium' : 'bold'}
        size={plan.label ? 14 : 18}
        color={plan.label ? 'primary' : 'orange'}
      >
        {plan.price ? plan.price : t(plan.label)}
      </Typography>
      {plan?.isCurrent && (
        <div>
          <Typography size="12" italic color="secondary">
            {t(`CURRENT_PLAN`)}
          </Typography>
        </div>
      )}
      {plan?.headline && <Typography>{t(plan.headline)}</Typography>}
      {plan?.ctaLabel && (
        <PrimaryButton
          className={classes.continueBtn}
          size="medium"
          onClick={onUpgradeHandler}
          trackingData={{
            section: SECTIONS.SUBSCRIPTION_NUDGE,
            component: COMPONENTS.FEATURE_COMPARISON_LIST,
            subComponent: SUB_COMPONENTS.CONTINUE_CTA,
          }}
        >
          {t(plan.ctaLabel)}
        </PrimaryButton>
      )}
    </div>
  );
};

const RenderFeatureSupport = ({ feature, plan }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const isSupportedMonthly = feature.supportedPlanMonthly.includes(plan.id);
  const isSupportedAnnually = feature.supportedPlanAnnually.includes(plan.id);

  if (isSupportedMonthly && isSupportedAnnually) {
    return <SpriteIcon icon="done" size={16} />;
  }

  if (!isSupportedMonthly && isSupportedAnnually) {
    return (
      <div>
        <SpriteIcon icon="done" size={16} />
        <Typography variant="p" color="secondary" italic>
          {t(`${TP}.ANNUAL_SUBSCRIPTION`)}
        </Typography>
      </div>
    );
  }

  if (!feature?.supportedPlanMonthly?.length && !feature?.supportedPlanAnnually?.length) {
    return (
      <div>
        <SpriteIcon icon="lock" size={16} />
        <Typography variant="p" color="secondary" italic>
          {t(`${TP}.ANNUAL_SUBSCRIPTION`)}
        </Typography>
      </div>
    );
  }

  return <SpriteIcon icon="lock" size={16} />;
};

const PricingTable = ({
  features,
  plans,
  selectedTab,
  onUpgradeHandler = () => {},
  hideHeader = false,
  hideFooter = false,
  styles = { root: '' },
  onlyBenefits = false,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <table
      className={classnames(classes.pricingTable, {
        [styles.root]: !!styles.root,
      })}
    >
      {!hideHeader && (
        <thead>
          <tr className={classes.tableHeader}>
            <th className={classes.tableHeader__feature}>
              <Typography weight="bold">{t(`${TP}.FN_PROFILE_FEATURES`)}</Typography>
            </th>
            {plans.map(plan => (
              <th
                className={classnames(classes.tableHeader__plan, {
                  [classes.tableHeader__cellBorder]: plans?.length > 1,
                })}
                key={plan.id}
              >
                <RenderPlanHeader
                  plan={plan}
                  selectedTab={selectedTab}
                  onUpgradeHandler={onUpgradeHandler}
                  onlyBenefits={onlyBenefits}
                />
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody
        className={classnames(classes.tableBody, {
          [classes.tableBodyWithFooter]: !hideFooter,
        })}
      >
        {features?.map(feature => (
          <tr key={feature.name}>
            <td className={classes.bodyFeatureCell}>{t(feature?.name)}</td>
            {plans?.map(plan => (
              <td className={classes.bodyPlanCell} key={plan.id}>
                <RenderFeatureSupport feature={feature} plan={plan} />
              </td>
            ))}
          </tr>
        ))}
        {!hideFooter && (
          <tr className={classnames(classes.tableHeader, classes.tableFooterActions)}>
            <td></td>
            {plans?.map(plan => (
              <td className={classes.tableHeader__plan} key={plan.id}>
                <RenderPlanHeader
                  plan={plan}
                  selectedTab={selectedTab}
                  onUpgradeHandler={onUpgradeHandler}
                  onlyBenefits={onlyBenefits}
                />
              </td>
            ))}
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default PricingTable;
